<template>
  <div>
    {{order}}
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementScalePropTableColumn} from "@/mixins/elementScalePropTableColumn";
import {mapGetters} from "vuex";

export default {
  name: 'element-order-table-column',
  components: {ClickToEdit},
  mixins: [ElementScalePropTableColumn],

  props: {},

  computed: {

    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements'
    }),
    order() {
      const thisSectionType = this.scope._self.section.type;
      if( thisSectionType === 'basic' ){
        return this.scope.$index+1;
      }

      const basicSection = this.sections.find(s => s.type === 'basic');
      const basicSectionCount = basicSection ? this.sectionsElements[basicSection.id]?.length : 0;
      if( thisSectionType === 'personal_library' ){
        return (basicSectionCount+1) + '.' + (+this.scope.$index+1);
      }

      const personalLibrarySection = this.sections.find(s => s.type === 'personal_library');
      const personalLibrarySectionCount = personalLibrarySection ? this.sectionsElements[personalLibrarySection.id]?.length : 0;
      if( thisSectionType === 'personal_manual' ){
        return (basicSectionCount+1) + '.' + (personalLibrarySectionCount +this.scope.$index + 1);
      }

      const personalManualSection = this.sections.find(s => s.type === 'personal_manual');
      const personalManualSectionCount = personalManualSection ? this.sectionsElements[personalManualSection.id]?.length : 0;
      if( thisSectionType === 'quality' ){
        const firstPart = basicSectionCount + 1 + ((personalLibrarySectionCount + personalManualSectionCount) ? 1 : 0 );
        return firstPart + '.' + (+this.scope.$index + 1);
      }
      return 999;
    },
  },
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
